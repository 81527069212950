import { uuidv4 } from 'lander/cf_utils'
import { CFErrorWithCause, ErrorOptions } from './error_with_cause'
import { pathJoin } from './path'

const CFFetcherErrorTypes = {
  NETWORK_ERROR: 'NETWORK_ERROR',
  SERVER_ERROR: 'SERVER_ERROR',
}
globalThis.CFFetcherErrorTypes = CFFetcherErrorTypes
export class CFFetcherError extends CFErrorWithCause {
  public type: string

  constructor(type: string, options?: ErrorOptions) {
    super(type, options)
    this.name = 'CFFetcherError'
    this.type = type
  }
}
globalThis.CFFetcherError = CFFetcherError

type EnhancedFetchOptions = {
  requestId?: string
  retries?: number
  timeoutMS?: number
  timeoutAfterRetrial?: number
  shouldCaptureServerError?: boolean
  convertThrowToErrorResponse?: boolean
  onlyOneInflightRequest?: boolean
}

interface FetcherOptions {
  debug?: boolean
  pathPrefix?: string
  headers?: Record<string, string>
  requestOptions?: FetcherRequestOptions
}
type FetcherRequestOptions = {
  callbackData?: any
  customEvent?: string
} & EnhancedFetchOptions

const FetcherRequestDefaultOptions: FetcherRequestOptions = {
  retries: 1,
  timeoutMS: -1,
  timeoutAfterRetrial: 1000,
  shouldCaptureServerError: false,
  convertThrowToErrorResponse: false,
  onlyOneInflightRequest: false,
}

type FetcherError = {
  error: string
}
export type FetcherResponse<T> = T | FetcherError

export function isResponseError<T>(response: FetcherResponse<T>): response is FetcherError {
  return typeof (response as FetcherError)?.error == 'string'
}

export const MANUALLY_ABORTED = 'Manually aborted'
export type InFlightRequest = {
  manuallyAborted: boolean
  abortController: AbortController
}
export default class Fetcher<T> {
  loading: boolean
  controller: AbortController
  signal: AbortSignal
  options: FetcherOptions
  url: string
  inflightRequests: Record<string, InFlightRequest>

  constructor(options?: FetcherOptions) {
    this.options = options || {}
    this.inflightRequests = {}
  }

  /*
   * This fetch call is an extension of original fetch which only fires
   * api with a loading state before and after the call.
   * Can also debounce greater or less than 500ms if required.
   * The fetch request returns the JSON promise and is abortable
   */
  public async fetch<T>(
    url: string,
    init: RequestInit,
    requestOptions: FetcherRequestOptions
  ): Promise<FetcherResponse<Response>> {
    const { callbackData, customEvent, ...enhancedFetchOptions } = {
      ...FetcherRequestDefaultOptions,
      ...(this.options?.requestOptions ?? {}),
      ...(requestOptions ?? {}),
    }

    if (this.loading && enhancedFetchOptions.onlyOneInflightRequest) {
      this.abort()
    }

    const requestId = uuidv4()
    enhancedFetchOptions.requestId = requestId
    const abortController = new AbortController()

    this.inflightRequests[requestId] = {
      manuallyAborted: false,
      abortController,
    }

    let response: Response
    this.url = url
    init = {
      ...(init ?? {}),
      signal: abortController.signal,
      headers: {
        ...this.options.headers,
        ...init.headers,
      },
    }

    this.setLoading(true, callbackData, customEvent)
    try {
      response = await this.enhancedFetch(url, init, enhancedFetchOptions)
      this.setLoading(false, callbackData, customEvent)
      if (this.options.debug) {
        console.log('[Fetch Request Completed]', response)
      }
      return response
    } catch (error) {
      this.setLoading(false, error, customEvent)
      let err = error
      if (this.inflightRequests[requestId].manuallyAborted) {
        err = MANUALLY_ABORTED
      } else {
        if (this.options.debug) {
          console.log('[Error During Fetch]', error)
        }
      }

      if (enhancedFetchOptions.convertThrowToErrorResponse) {
        return { error: err }
      } else {
        throw err
      }
    } finally {
      delete this.inflightRequests[requestId]
    }
  }

  public async post<T>(
    url: string,
    data?: Record<string, unknown>,
    requestOptions?: FetcherRequestOptions
  ): Promise<FetcherResponse<T>> {
    const response = await this.fetch<T>(
      url,
      {
        method: 'POST',
        body: JSON.stringify(data),
      },
      requestOptions
    )
    if (isResponseError(response)) {
      return response
    } else {
      try {
        return (await response.json()) as FetcherResponse<T>
      } catch (error) {
        return { error }
      }
    }
  }

  async enhancedFetch(url: string, init: RequestInit, opts: EnhancedFetchOptions): Promise<Response> {
    const { retries, timeoutMS, timeoutAfterRetrial, shouldCaptureServerError, requestId } = opts
    let lastErr
    for (let i = 0; i < retries; i++) {
      try {
        if (i > 0) {
          await new Promise((resolve) => setTimeout(resolve, timeoutAfterRetrial))
        }
        if (shouldCaptureServerError) {
          return await this.fetchWithTimeout(url, init, timeoutMS, requestId).then((response: Response) => {
            if (response.status >= 500) {
              throw new CFFetcherError(CFFetcherErrorTypes.SERVER_ERROR)
            }
            return response
          })
        } else {
          return await this.fetchWithTimeout(url, init, timeoutMS, requestId)
        }
      } catch (err) {
        if (err instanceof CFFetcherError && err.type == CFFetcherErrorTypes.SERVER_ERROR) {
          throw err
        }
        lastErr = err
      }
    }

    throw new CFFetcherError(CFFetcherErrorTypes.NETWORK_ERROR, { cause: lastErr })
  }

  fetchWithTimeout(url: string, init: RequestInit, timeoutDuration = 1000, requestId: string): Promise<Response> {
    if (timeoutDuration > 0) {
      setTimeout(() => this.abort(requestId), timeoutDuration)
    }
    if (url.startsWith('http') || url.startsWith('https')) {
      return fetch(url, init)
    } else {
      const finalUrl = pathJoin(this.options.pathPrefix, url)
      return fetch(finalUrl, init)
    }
  }

  public abort(requestId?: string): void {
    const requestsToAborts = requestId ? [requestId] : Object.keys(this.inflightRequests) ?? []
    requestsToAborts.forEach((key) => {
      const inflightRequest = this.inflightRequests[key]
      if (!inflightRequest) return
      if (!inflightRequest?.manuallyAborted) {
        if (this.options.debug) {
          console.log(`[Aborting Request][RequestID=${key}]`, this.url)
        }
        inflightRequest.abortController.abort()
        inflightRequest.manuallyAborted = true
      }
    })
  }

  public setLoading(isLoading: boolean, details: Record<string, unknown>, customName: string): void {
    let loadingEvent: CustomEvent
    const startEvent = (customName && customName + 'Started') || 'CFFetchStarted'
    const endEvent = (customName && customName + 'Finished') || 'CFFetchFinished'

    if (isLoading && !this.loading) {
      if (this.options.debug) {
        console.log('[Loading Started]', startEvent)
      }
      this.loading = true
      loadingEvent = new CustomEvent(startEvent, {
        detail: details,
      })
    } else if (!isLoading && this.loading) {
      if (this.options.debug) {
        console.log('[Loading Finished/Aborted]', endEvent)
      }

      this.loading = false
      loadingEvent = new CustomEvent(endEvent, {
        detail: details,
      })
    }

    if (loadingEvent) {
      document.dispatchEvent(loadingEvent)
    }
  }
}

globalThis.CFFetcher = globalThis.CFFetcher || Fetcher
export const CFFetch = async <T>(
  url: string,
  data: RequestInit,
  requestOptions: FetcherRequestOptions
): Promise<Response> => {
  const fetcher = new Fetcher()
  return (await fetcher.fetch<T>(url, data, requestOptions)) as Response
}
globalThis.CFFetch = CFFetch
