export function pathJoin(...args: string[]): string {
  if (args.length === 0) return ''

  const firstNotEmptyIndex = args.findIndex((arg) => arg?.length > 0)
  if (firstNotEmptyIndex === -1) return ''

  let result = args[firstNotEmptyIndex]
  for (let i = firstNotEmptyIndex + 1; i < args.length; i++) {
    const part = args[i] ?? ''
    if (part.length === 0) continue

    let startChar = 0
    while (startChar < part.length - 1 && part[startChar] === '/') {
      startChar += 1
    }
    const shouldAddSlash = result.length > 0 && result[result.length - 1] !== '/'
    result += (shouldAddSlash ? '/' : '') + part.slice(startChar)
  }
  return result
}
